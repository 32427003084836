@mixin center {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

@mixin underline {
    &::after {
        content: '';
        display: block;
        margin: 2rem auto;
        height: 2px;
        width: 12rem;
        background-color: #777;
        @media(min-width: 768px) {
            margin: 3rem auto;
        } 
    }
}

@mixin clearfix {
    &::after {
        content: '';
        display: block;
        clear: both;
    }
}