@charset 'UTF-8';

// 1. Configuration and helpers

@import
    'abstracts/variables',
    'abstracts/functions',
    'abstracts/mixins';
// 2. Vendors



// 3. Base stuff

@import
    'base/base',
    'base/animations',
    'base/type',
    'base/utils',
    'base/grid';

// 4. Layout-related stuff



// 5. Components

@import
    'components/button',
    'components/navigation',
    'components/popup';

// 6. Page-specific styles

@import
  'pages/home';

// 7. Themes
