/* ----- Size ----- */
/* ----- Colors ----- */
/* ----- Grid ----- */
*,
*::before,
*::after {
  margin: 0;
  padding: 0;
  box-sizing: inherit;
}
html {
  font-size: 62.5%;
}
body {
  font-family: "Josefin sans", sans-serif;
  font-size: 1.6rem;
  font-weight: 300;
  color: #555;
}
p {
  line-height: 1.7;
}
section {
  padding: 6rem 2rem;
  border-bottom: 1px solid #e9e8e8;
}
@media (min-width: 768px) {
  section {
    padding: 10rem 0;
  }
}
.intro-text {
  max-width: 50rem;
  margin: 0 auto;
  font-size: 1.8rem;
  text-align: center;
}
@media (min-width: 768px) {
  .intro-text {
    font-size: 2.4rem;
    max-width: 70rem;
  }
}
.heading-primary {
  font-weight: 400;
  color: #fff;
  opacity: 0.8;
  display: inline-block;
  font-size: 4.8rem;
}
@media (min-width: 768px) {
  .heading-primary {
    font-size: 7.2rem;
  }
}
.heading-secondary {
  font-size: 2.4rem;
  font-weight: 400;
}
.heading-secondary::after {
  content: "";
  display: block;
  margin: 2rem auto;
  height: 2px;
  width: 12rem;
  background-color: #777;
}
@media (min-width: 768px) {
  .heading-secondary::after {
    margin: 3rem auto;
  }
}
@media (min-width: 768px) {
  .heading-secondary {
    font-size: 4.5rem;
  }
}
.heading-tertiary {
  font-size: 2.2rem;
  font-weight: 400;
}
@media (min-width: 768px) {
  .heading-tertiary {
    font-size: 2.4rem;
  }
}
.u-text-center {
  text-align: center !important;
}
.row {
  max-width: 114rem;
  margin: 0 auto;
}
.row::after {
  content: "";
  display: block;
  clear: both;
}
@media (min-width: 768px) {
  .row {
    display: table;
  }
}
@media (min-width: 768px) {
  .row [class^=col-] {
    width: calc(50% - 8rem);
    float: left;
  }
}
.btn, .btn:link, .btn:visited {
  position: relative;
  display: inline-block;
  text-decoration: none;
  font-size: 1.8rem;
  color: #fff;
  background-color: #293335;
  padding: 1.5rem 4rem;
  border: 1px solid #293335;
  border-radius: 10rem;
  box-shadow: 0 1rem 2rem rgba(0, 0, 0, 0.17);
  transition: all 0.2s;
  margin-top: 2.2rem;
}
@media (min-width: 768px) {
  .btn, .btn:link, .btn:visited {
    font-size: 2rem;
  }
}
.btn::after {
  position: absolute;
  top: 0;
  left: 0;
  display: block;
  content: "";
  height: 100%;
  width: 100%;
  border-radius: 10rem;
  z-index: -100;
  transition: all 400ms;
  color: inherit;
  background: inherit;
}
.btn:hover {
  transform: translateY(-3px);
  box-shadow: 0 2rem 3rem rgba(0, 0, 0, 0.17);
}
.btn:hover::after {
  transform: scaleX(1.4) scaleY(1.6);
  opacity: 0;
}
.btn:active {
  transform: translateY(-1px);
  box-shadow: 0 1rem 2rem rgba(0, 0, 0, 0.17);
}
.btn__hire:link, .btn__hire:visited {
  opacity: 0.7;
  color: #fff;
  border: 1px solid #fff;
  background-color: transparent;
}
.navigation__checkbox {
  display: none;
}
.navigation__button {
  position: fixed;
  top: 1.5rem;
  right: 1.5rem;
  height: 5rem;
  width: 5rem;
  text-align: center;
  background-color: #fff;
  border-radius: 50%;
  z-index: 300;
  box-shadow: 0 1rem 3rem rgba(0, 0, 0, 0.2);
  cursor: pointer;
}
@media (min-width: 768px) {
  .navigation__button {
    top: 4rem;
    right: 4rem;
    height: 7rem;
    width: 7rem;
  }
}
.navigation__background {
  position: fixed;
  top: 2rem;
  right: 2rem;
  height: 3rem;
  width: 3rem;
  border-radius: 50%;
  background: #293335;
  background-size: cover;
  background-position: center;
  z-index: 100;
  transition: all 800ms cubic-bezier(0.86, 0, 0.07, 1);
}
@media (min-width: 768px) {
  .navigation__background {
    top: 4.5rem;
    right: 4.5rem;
    height: 6rem;
    width: 6rem;
  }
}
.navigation__nav {
  position: fixed;
  top: 0;
  left: 0;
  height: 100vh;
  opacity: 0;
  width: 0;
  visibility: hidden;
  z-index: 200;
  transition: all 800ms cubic-bezier(0.68, -0.55, 0.265, 1.55);
}
.navigation__list {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
  width: 100%;
  list-style: none;
}
.navigation__item {
  margin: 1rem;
}
.navigation__link:link, .navigation__link:visited {
  display: inline-block;
  padding: 1rem 2rem;
  text-transform: uppercase;
  color: #91abaa;
  font-size: 2.4rem;
  text-decoration: none;
  transition: all 0.2s;
}
@media (min-width: 768px) {
  .navigation__link:link, .navigation__link:visited {
    font-size: 3.8rem;
  }
}
.navigation__link:link span, .navigation__link:visited span {
  margin-right: 1.5rem;
  display: inline-block;
}
.navigation__link:hover {
  color: #f4f4f4;
  transform: scale(1.1);
}
.navigation__checkbox:checked ~ .navigation__background {
  transform: scale(80);
}
.navigation__checkbox:checked ~ .navigation__nav {
  width: 100%;
  visibility: visible;
  opacity: 1;
}
.navigation__icon {
  position: relative;
  margin-top: 2.5rem;
}
@media (min-width: 768px) {
  .navigation__icon {
    margin-top: 3.5rem;
  }
}
.navigation__icon, .navigation__icon::before, .navigation__icon::after {
  display: inline-block;
  width: 2.2rem;
  height: 2px;
  background-color: #555;
}
@media (min-width: 768px) {
  .navigation__icon, .navigation__icon::before, .navigation__icon::after {
    width: 3rem;
  }
}
.navigation__icon::before, .navigation__icon::after {
  content: "";
  position: absolute;
  left: 0;
  transition: all 200ms;
}
.navigation__icon::before {
  top: -0.8rem;
}
.navigation__icon::after {
  top: 0.8rem;
}
.navigation__button:hover .navigation__icon::before {
  top: -1rem;
}
.navigation__button:hover .navigation__icon::after {
  top: 1rem;
}
.navigation__checkbox:checked + .navigation__button .navigation__icon {
  background-color: transparent;
}
.navigation__checkbox:checked + .navigation__button .navigation__icon::before {
  top: 0;
  transform: rotate(135deg);
}
.navigation__checkbox:checked + .navigation__button .navigation__icon::after {
  top: 0;
  transform: rotate(-135deg);
}
.popup {
  position: fixed;
  top: 0;
  left: 0;
  height: 100vh;
  min-height: 500px;
  width: 100vw;
  background-color: rgba(0, 0, 0, 0.8);
  border-radius: 3px;
  text-align: center;
  z-index: 400;
  opacity: 0;
  visibility: hidden;
  transition: all 0.3s;
}
.popup:target {
  opacity: 1;
  visibility: visible;
}
.popup__close:link, .popup__close:visited {
  position: absolute;
  top: -2rem;
  right: 0.5rem;
  display: inline-block;
  color: #555;
  font-size: 5.5rem;
  font-weight: 100;
  text-decoration: none;
  transition: all 0.3s;
}
.popup__close:hover {
  color: #293335;
}
.popup__right {
  font-size: 1.7rem;
  width: 80%;
  max-width: 1000px;
  height: auto;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background: #fff;
  box-shadow: 0 1rem 2rem rgba(0, 0, 0, 0.8);
  border-radius: 3px;
}
@media (min-width: 768px) {
  .popup__right {
    font-size: 2.3rem;
  }
}
.popup__right h2 {
  margin: 2.5rem 0 1rem 0;
}
@media (min-width: 768px) {
  .popup__right h2 {
    margin: 5rem 0;
  }
}
@media (max-width: 768px) {
  .popup__top {
    padding: 1rem;
  }
}
.popup__contact-info {
  background: #293335;
  color: #91abaa;
  margin-top: 2rem;
  padding: 2rem 1rem 1rem 1rem;
}
@media (min-width: 768px) {
  .popup__contact-info {
    padding: 5rem 0 4rem 0;
    margin-top: 6rem;
  }
}
.popup__contact-info ul {
  padding-bottom: 0;
}
.popup__contact-info a {
  color: #91abaa !important;
  text-decoration: none;
}
/* ------------------------------------- */
/* ----- Header ----- */
/* ------------------------------------- */
.header {
  position: relative;
  height: 100vh;
  min-height: 600px;
  text-align: center;
}
.header__text-box {
  position: absolute;
  top: 50%;
  left: 50%;
  width: 80%;
  max-width: 114rem;
  max-width: 150rem;
  transform: translate(-50%, -50%);
  opacity: 0.95;
}
.header__description {
  max-width: 50rem;
  margin: 0px auto 20px auto;
  font-size: 1.8rem;
  color: #fff;
  opacity: 0.7;
}
@media (min-width: 768px) {
  .header__description {
    width: 70%;
    font-size: 2.5rem;
  }
}
.header__director {
  position: absolute;
  bottom: 0;
  left: 50%;
  padding-bottom: 1rem;
  transform: translateX(-50%);
  color: #fff;
  opacity: 0.7;
}
@media (min-width: 768px) {
  .header__director {
    font-size: 1.7rem;
  }
}
.background-img {
  background-image: linear-gradient(rgba(0, 0, 0, 0.6), rgba(0, 0, 0, 0.6)), url("header.a85a71f6.webp");
  background-size: cover;
  background-position: center;
  text-align: center;
}
/* ------------------------------------- */
/* ----- Intro ----- */
/* ------------------------------------- */
.intro {
  text-align: center;
  background: #f4f4f4;
}
@media (min-width: 768px) {
  .intro {
    padding: 8rem 0;
  }
}
/* ------------------------------------- */
/* ----- About ----- */
/* ------------------------------------- */
/* ------------------------------------- */
/* ----- Carft ----- */
/* ------------------------------------- */
.craft {
  background: #f9f9f9;
}
.craft .row {
  margin-top: 3rem;
}
@media (min-width: 768px) {
  .craft .row {
    margin-top: 5rem;
    display: table;
    padding: 3rem 10rem;
  }
}
.craft .hack {
  border: none;
  background: transparent;
  box-shadow: none;
}
.craft__item {
  display: block;
  margin: auto;
  max-width: 50rem;
  text-align: center;
  border: 1px solid #e9e8e8;
  padding: 2rem 1rem;
  background: #fff;
  box-shadow: 0 0.2rem 0.2rem rgba(0, 0, 0, 0.1);
  border-radius: 3px;
}
.craft__item:first-of-type {
  margin: 0 auto 2rem auto;
}
@media (min-width: 768px) {
  .craft__item {
    display: table-cell;
    padding: 3rem;
  }
}
.craft__img {
  height: 9rem;
  display: inline-block;
  margin-bottom: 1.5rem;
  fill: #555;
}
.craft__description {
  margin: 1.5rem 0;
  font-size: 1.8rem;
}
@media (min-width: 768px) {
  .craft__description {
    font-size: 2.2rem;
  }
}
/* ------------------------------------- */
/* ----- Skills ----- */
/* ------------------------------------- */
.skill {
  background: #f8f8f8;
  text-align: center;
}
.skill .row {
  max-width: 500px;
}
.skill__img {
  display: inline-block;
  fill: #555;
  height: 10rem;
  margin: 1rem 2rem;
}
.skill [href^=http] {
  color: inherit;
  text-decoration: none;
  border-bottom: 1px dashed #bdbcbc;
  transition: all 0.2s;
}
.skill [href^=http]:hover {
  border-bottom: 1px dashed #818080;
}
.skill .btn-text {
  margin-top: 2rem;
}
/* ------------------------------------- */
/* ----- Work ----- */
/* ------------------------------------- */
.work {
  text-align: center;
}
.work__construction {
  display: block;
  margin: 3rem auto;
  height: 10rem;
}
@media (min-width: 768px) {
  .work__construction {
    height: 20rem;
  }
}
.work [href^=http] {
  color: inherit;
  text-decoration: none;
  border-bottom: 1px dashed #bdbcbc;
  transition: all 0.2s;
}
.work [href^=http]:hover {
  border-bottom: 1px dashed #818080;
}
/* ------------------------------------- */
/* ----- Footer ----- */
/* ------------------------------------- */
.footer {
  background: #293335;
  color: #91abaa;
  padding: 5rem 5rem 0rem 5rem;
}
.footer__social-links {
  list-style: none;
  text-align: center;
  padding-bottom: 6rem;
}
.footer__social-link {
  display: inline-block;
  font-size: 2rem;
}
.footer__social-link:not(:last-child) {
  margin-right: 1rem;
}
@media (min-width: 768px) {
  .footer__social-link {
    font-size: 2.6rem;
  }
  .footer__social-link:not(:last-child) {
    margin-right: 2rem;
  }
}
.footer__social-link a {
  color: inherit;
  text-decoration: none;
  transition: all 0.2s;
}
.footer__social-link a:hover {
  color: #c6d8d7;
}
.footer__outro {
  text-align: center;
  max-width: 114rem;
  margin: 0 auto;
  padding: 3rem 1rem 4rem 1rem;
  border-top: 1px solid #394344;
}
@media (min-width: 768px) {
  .footer__outro::after {
    content: "";
    display: block;
    clear: both;
  }
  .footer__outro p {
    float: left;
  }
  .footer__outro p:not(:last-child) {
    float: right;
  }
}
.footer__info {
  font-size: 1.8rem;
}
@media (min-width: 768px) {
  .footer__info {
    font-size: 2.3rem;
  }
}
.footer__info a {
  border-bottom: 1px dashed;
  color: inherit;
  text-decoration: none;
  transition: all 0.2s;
}
.footer__info a:hover {
  color: #f4f4f4;
}
.footer .number {
  color: #91abaa !important;
  margin: 2rem 0 0 0;
  font-size: 1.8rem;
  text-decoration: none;
}
@media (min-width: 768px) {
  .footer .number {
    font-size: 2.3rem;
  }
}
/*# sourceMappingURL=index.bb1f99de.css.map */
