
/* ----- Size ----- */

$default-font-size: 1.6rem;


/* ----- Colors ----- */

$color-white: #fff;
$color-white-1: #f4f4f4;
$color-white-2: #91abaa;
$color-black: #000;
$color-black-1: #555;
$color-black-0: #293335;

/* ----- Grid ----- */

$gutter-vertical: 8rem;
